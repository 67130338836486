<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="600"
    :before-close="handleClose"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      v-if="dialogVisible"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="名称：" prop="articleTypeName">
        <el-input v-model="ruleForm.articleTypeName" maxlength="48"></el-input>
      </el-form-item>
      <el-form-item label="排序：" prop="sort">
        <el-input v-model="ruleForm.sort" maxlength="48"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
        <el-button @click="resetForm('ruleForm')">重 置</el-button>
      </el-form-item>
    </el-form>
    <!-- <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleClose" size="mini">确 定</el-button>
      <el-button @click="handleClose" size="mini">重 置</el-button>
    </span>-->
  </el-dialog>
</template>

<script>
import { articleTypeSave } from "@/api/SystemData";
import { provinceCity } from "@/utils/provinceCity";
import { dictionaryBatch } from "@/api/policy.js";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (v && this.editData.id) {
          this.title = "编辑-" + this.editData.articleTypeName;
          this.ruleForm = JSON.parse(JSON.stringify(this.editData));
        } else {
          this.title = "添加";
          this.ruleForm = {
            articleTypeName: "",
            sort: "1",
          };
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      ruleForm: {
        articleTypeName: "",
        sort: "1",
      },
      title: "",
      regionList: provinceCity,
      rules: {
        articleTypeName: [
          { required: true, message: "请输入姓名", trigger: "change" },
        ],
      },
      loading: true,
      options: [],
    };
  },
  async created() {
    //  this.initSearch();
  },
  methods: {
    handleClose() {
      this.$emit("update:dialogVisible", false);
    },
    //  initSearch() {
    //    dictionaryBatch({ codes: "customerServiceType" }).then(res => {
    //      const { customerServiceType } = res.data;
    //      this.typeList = customerServiceType;
    //    });
    //  },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(this.ruleForm));
          articleTypeSave({ ...data }).then((res) => {
            this.handleClose();
            this.$message.success("保存成功！");
            this.$emit("changeList", 1);
          });
        } else {
          console.log("error submit!!");
          this.$message.error("保存失败！");
          return false;
        }
      });
    },
    resetForm(type) {
      if (this.editData.id) {
        this.ruleForm = JSON.parse(JSON.stringify(this.editData));
      } else {
        this.ruleForm = {
          articleTypeName: "",
          sort: "1",
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  background: #4b7bca;
  .el-dialog__title {
    color: #fff;
  }
}
</style>
